<template>
    <div class="helpCenter">
        <div class="helpWrap"> 
            <div class="helpCenter-left">
                <div class="title">帮助中心</div>
                <ul>
                    <!-- 循环数据在点击调用changeli方法时将当前索引和本条数据传进去,并使用当前数据show的bool值添加或移除样式 -->
                    <li :class="[{active:item.show}]" @click="changeli(index,item)" v-for="(item,index) in headerData" :key="index">
                        <!-- 在这里打印出boll值方便查看 -->
                        {{item.name}}

                        <!-- 判断当前这条数据的bool值如果是true就打开二级菜单,如果是false就关闭二级菜单 -->
                            <ul v-show="item.show" class="help-list"> 
                                <!-- 循环二级菜单数据并使用.stop阻止冒泡 -->
                                <li v-for="(a,index) in item.list"  :class="activeClass == index ? 'active' : ''" @click.stop="doThis(index,a.id)" :key="index">{{a.name}}</li>
                            </ul>
                    
                    </li>
                </ul>
            </div>
            <div class="helpCenter-right">
                <div></div>
                <div  v-for="(item1,i1) in detailArr" :key="i1" v-html="item1.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"helpCenter",
    data(){
        return{
            activeClass: -1,
            defauldId:"7",
            isShow:"",
            headerData: [
            //     {
            //     name: '导航1',
            //     list: ['子集', '子集', '子集', '子集', '子集'],
            //     show: false
            // }, {
            //     name: '导航2',
            //     list: ['子集', '子集', '子集', '子集', '子集'],
            //     show: false
            // }, {
            //     name: '导航3',
            //     list: ['子集', '子集', '子集', '子集', '子集'],
            //     show: false
            // }, {
            //     name: '导航4',
            //     list: ['子集', '子集', '子集', '子集', '子集'],
            //     show: false
            // }, {
            //     name: '导航5',
            //     list: ['子集', '子集', '子集', '子集', '子集'],
            //     show: false
            // }
            ],
            detailArr:[],
        }
    },
    created() {
        this.getTree();
        console.log(this.$route.params.id);
        if (this.$route.params.id!=undefined) {
            this.$http.get("/help/cate/detail?cateId="+this.$route.params.id).then(res=>{   
               this.detailArr=res.data.data;
               console.log(this.detailArr,88)
            })
        }else{
            this.defaultDetail()
        }
    },
    watch: {
        $route(val,val1){
            this.$http.get("/help/cate/detail?cateId="+this.$route.params.id).then(res=>{   
               this.detailArr=res.data.data;
            })
        }
    },
    methods: {
        getTree(){
            this.$http.get("/help/cate").then(res=>{   
                this.headerData=res.data.data;
                this.headerData.forEach((item,i)=>{
                    this.$set(item, "show", false);
                })
                this.defauldId=this.headerData[0].list[0].id;
                // this.defaultDetail()
                console.log(  this.headerData)
            })
            
        },

        changeli(ind, item) {
            // 先循环数据中的show将其全部置为false,此时模板里的v-if判断生效关闭全部二级菜单,并移除样式

            this.headerData.forEach(i => {

                // 判断如果数据中的headerData[i]的show属性不等于当前点击li的show属性那么headerData[i]等于false
                if (i.show !== this.headerData[ind].show) {
                    i.show = false;
                };
            });
            item.show = !item.show;//当前点击li取反，取反(true或false)
        },
        doThis(index,cateId) {
            this.activeClass = index;
             window.scrollTo(0,0);
            //  let childNode=document.getElementsByClassName("active")[0].children[0];
            //  childNode.style.display="none";
            //  this.headerData.forEach(i => {
            //    i.show=false;
            // });
            this.$router.push({
                name:"helpCenter",
                params:{
                    id:cateId
                }
            })


            this.$http.get("/help/cate/detail?cateId="+cateId).then(res=>{   
               this.detailArr=res.data.data;
            })
        },
        defaultDetail(){
            this.$http.get("/help/cate/detail?cateId="+this.defauldId).then(res=>{   
               this.detailArr=res.data.data;
            })
        }
    },
}
</script>

<style lang="scss" scope="">
li{
    list-style: none;
}
*{
    margin: 0;
    padding: 0;
}
.helpCenter{
    width: 100%;
    background: #f5f5f5;

    .helpWrap{
        width: 1200px;
         margin: 0 auto;
          display: flex;
          margin-top: 20px;
          height: 100%;
        .helpCenter-left {
            -webkit-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
            width: 200px;
            height: 100%;
            background-color: #f5f5f5;
            color: #333;
             border: 1px solid #d82229;
             flex-shrink: 0;
             .title{
                 height: 50px;
                 text-align: center;
                 line-height: 50px;
                 background: #d82229;
                 color: #fff;
             }
            >ul {
                width: 100%;
                // @include clearfix;
                >li {
                    width: 100%;
                    border: 1px solid #ffffff;
                    cursor: pointer; // float: left;
                    color: 20px;
                    text-align: center;
                    line-height: 50px;
                    // &:hover {
                    //     background-color: #ff9800;
                    // }
                    >ul {
                        width: 100%;
                        background: #fff;
                        li{
                            &:hover{
                                color: #d82229;
                            }
                        }
                        // .active {
                        //     color: #d82229;
                        // }
                    }
                }
                
            }
        }
        .helpCenter-right{
            width: 100%;
            margin-left: 20px;
            background: #fff;
            padding:20px 30px 30px 30px;
            min-height: 312px;
        }
     }
}
</style>